
@font-face {
  font-family: 'Poppins-T';
  src: url('./fonts/Poppins-Thin.ttf') format('truetype'); /* Adjust the path and format as needed */
  /* Add more src entries for different file formats if available */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-L';
  src: url('./fonts/Poppins-Light.ttf') format('truetype'); /* Adjust the path and format as needed */
  /* Add more src entries for different file formats if available */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype'); /* Adjust the path and format as needed */
  /* Add more src entries for different file formats if available */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-M';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype'); /* Adjust the path and format as needed */
  /* Add more src entries for different file formats if available */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-B';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype'); /* Adjust the path and format as needed */
  /* Add more src entries for different file formats if available */
  font-weight: normal;
  font-style: normal;
}

body,html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
